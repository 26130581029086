import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import withAuth from "../components/auth/withAuth";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withSnackbar } from "notistack";

import ReactSelect from "react-select";
import Carousel, { Modal, ModalGateway } from "react-images";

import { DateTimePicker } from "@material-ui/pickers";
import { TextField } from "final-form-material-ui";

import { withTranslation } from "react-multi-lang";

import bankholidays from "../bankholidays.json";

import moment from "moment";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import ImageCompressor from "uppy-plugin-image-compressor";

import { Form, Field, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { FieldArray } from "react-final-form-arrays";

import convertToBooleanValue from "../components/utils/formUtilities";
import fetchClient from "../components/utils/fetchClient";
import isVideo from "./utils/isVideo";

const updateValue = ([name, newvalue], state, { changeValue }) => {
    changeValue(state, name, value => newvalue);
};

class renderFileUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lightboxOpen: false,
            files: [],
            photoIndex: 0
        };

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.fileuploader = new Uppy({ id: "ma", autoProceed: true })
            .use(ImageCompressor, {
                maxWidth: 1000,
                quality: 0.6
            })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "ma",
                                upload_type: "ma",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp) => {
                this.props.mutators.updateValue(`tasks.${this.props.index}.taskFiles`, [...this.props.input.value, resp.uploadURL]);
            });
    }

    componentWillUnmount() {
        this.fileuploader.close();
    }

    openLightbox(files, index) {
        this.setState({
            lightboxOpen: true,
            files: files,
            photoIndex: index
        });
    }

    closeLightbox() {
        this.setState({
            lightboxOpen: false,
            files: [],
            photoIndex: 0
        });
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <DragDrop
                    uppy={this.fileuploader}
                    locale={{
                        strings: {
                            dropHereOr: t("add.Upload") + " %{browse}",
                            browse: t("add.Browse")
                        }
                    }}
                />
                <StatusBar uppy={this.fileuploader} hideUploadButton hideAfterFinish={false} showProgressDetails />

                <div style={{ paddingBottom: 10 }}>
                    {this.props.input.value
                        ? this.props.input.value.map((image, index) => (
                              <img
                                  key={index}
                                  style={{
                                      paddingLeft: 2,
                                      paddingRight: 2
                                  }}
                                  height="50"
                                  width="50"
                                  className="uploaded-image"
                                  src={!isVideo(image) ? image : require("../assets/video.png")}
                                  alt={image.split("/").pop()}
                                  onClick={() => this.openLightbox(this.props.input.value, index)}
                              />
                          ))
                        : null}
                </div>
                <ModalGateway>
                    {this.state.lightboxOpen ? (
                        <Modal
                            onClose={this.closeLightbox}
                            styles={{
                                blanket: (base, state) => ({ ...base, zIndex: 1100 }),
                                positioner: (base, state) => ({ ...base, zIndex: 1110 }),
                                dialog: (base, state) => ({ ...base, zIndex: 1120 })
                            }}
                        >
                            <Carousel
                                currentIndex={this.state.photoIndex}
                                views={this.props.input.value.map(file => ({
                                    src: file
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        );
    }
}

const renderCheckbox = ({ input, label }) => {
    let val = convertToBooleanValue(input.checked);
    return (
        <div>
            <FormControlLabel control={<Checkbox checked={val} onChange={input.onChange} />} label={label} />
        </div>
    );
};

const renderSelect = ({ input, label, options, classes, meta }) => (
    <div className={classes.formControl}>
        <InputLabel shrink error={meta.invalid && meta.modified} style={{ paddingBottom: 10 }}>
            {label}
        </InputLabel>
        <ReactSelect
            defaultValue={
                input.value && {
                    value: input.value,
                    label: options.find(o => o.value === input.value).label
                }
            }
            isClearable
            onChange={value => input.onChange(value ? value.value : null)}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: "#f8a34c",
                    primary50: "#f79632",
                    primary75: "#f68919",
                    primary: "#f57c00"
                }
            })}
            options={options}
        />
        {meta.invalid && meta.modified && (
            <InputLabel shrink error={true} style={{ paddingTop: 10 }}>
                {meta.error}
            </InputLabel>
        )}
    </div>
);

const renderDatePicker = ({ input, label, classes, checkIsSpecialDate, taskPriority }) => (
    <DateTimePicker
        value={input.value ? input.value : null}
        ampm={false}
        label={label}
        autoOk={true}
        fullWidth={true}
        InputLabelProps={{
            shrink: true
        }}
        format="DD/MM/YYYY HH:mm"
        className={classes.formControl}
        onChange={value => {
            checkIsSpecialDate(value);
            input.onChange(value ? value : null);
        }}
        shouldDisableDate={value => {
            var date = moment(value).format("YYYY-MM-DD");

            const weekday = moment(date).day();

            if (taskPriority < 2) {
                if (bankholidays.includes(date)) {
                    return true;
                } else {
                    if (weekday === 6 || weekday === 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }}
    />
);

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: 10
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        padding: theme.spacing()
    },
    flex: {
        flex: 1
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    expansion: {
        paddingTop: 5,
        paddingBottom: 15
    }
});

class AddMultiple extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            task: [],
            propertiesList: [],
            categoriesList: [],
            usersList: [],
            isLoading: true,
            isDisabled: true
        };

        this.saveChanges = this.saveChanges.bind(this);
        this.checkIsSpecialDate = this.checkIsSpecialDate.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    handleBack() {
        const backTo = localStorage.getItem("maBackTo");
        if (backTo) {
            localStorage.removeItem("maBackTo");
            this.props.history.push(backTo);
        } else {
            this.props.history.push("/");
        }
    }

    async componentDidMount() {
        let properties = await this.fetchProperties();
        let categories = await this.fetchCategories();
        let users = await this.fetchUsers();

        var maNewTask = JSON.parse(localStorage.getItem("maNewTask"));
        if (maNewTask) {
            var task = {
                taskDesc: maNewTask.taskDescription,
                propertyId: maNewTask.propertyId,
                issueId: maNewTask.issueId
            };

            localStorage.removeItem("maNewTask");
        }

        this.setState({
            task: { ...this.state.task, ...task },
            propertiesList: properties,
            categoriesList: categories,
            usersList: users,
            isLoading: false
        });
    }

    async fetchProperties() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/properties")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchCategories() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/categories")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchUsers() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/users")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    saveChanges(values) {
        var self = this;
        self.setState({ isDisabled: true });

        fetchClient
            .post("/ma/new-multiple", values)
            .then(function (response) {
                self.setState({ isDisabled: false });

                const backTo = localStorage.getItem("maBackTo");
                if (backTo) {
                    localStorage.removeItem("maBackTo");
                    self.props.history.push(backTo);
                } else {
                    self.props.history.push("/");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    checkIsSpecialDate(value) {
        if (value) {
            const date = moment(value).format("YYYY-MM-DD");
            const weekday = moment(date).day();

            //fuck react
            if (bankholidays.includes(date)) {
                this.props.enqueueSnackbar(this.props.t("add.BankHoliday"), { preventDuplicate: true });
                return true;
            }

            if (weekday === 6 || weekday === 0) {
                this.props.enqueueSnackbar(this.props.t("add.Weekend"), { preventDuplicate: true });
                return true;
            }

            return false;
        }
    }

    calculateDate(values) {
        let propertyId = values.propertyId;

        let isAvailable = 0;
        let nextCheckout = null;
        if (propertyId) {
            isAvailable = this.state.propertiesList.find(o => o.propertyId === propertyId).isAvailable;
            nextCheckout = this.state.propertiesList.find(o => o.propertyId === propertyId).checkinData.departure;
        }

        let date = nextCheckout ? moment(nextCheckout, "YYYY-MM-DD").format("YYYY-MM-DD 00:00") : null;

        if (isAvailable) {
            date = moment().format("YYYY-MM-DD");
        } else {
            if (date) {
                date = moment(nextCheckout, "YYYY-MM-DD").format("YYYY-MM-DD");
            }
        }

        //check again
        this.checkIsSpecialDate(date);

        return date;
    }

    formIsValid(valid) {
        this.setState({ isDisabled: !valid });
    }

    render() {
        const { classes, t } = this.props;

        const required = value => (value ? undefined : t("add.Required"));

        let submit;

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton onClick={this.handleBack} className={classes.menuButton} color="inherit" aria-label={t("misc.Back")}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading ? t("titles.NewMultipleTask") : t("titles.Loading") + "..."}
                        </Typography>
                        <Button color="inherit" onClick={event => submit(event)} disabled={this.state.isDisabled}>
                            {t("add.Add")}
                        </Button>
                    </Toolbar>
                </AppBar>

                {this.state.isLoading && <LinearProgress color="secondary" />}
                <div className={classes.content}>
                    {!this.state.isLoading ? (
                        <Form
                            onSubmit={this.saveChanges}
                            mutators={{
                                ...arrayMutators,
                                updateValue
                            }}
                            initialValues={this.state.task}
                            render={({ form, handleSubmit, values, form: { mutators } }) => {
                                const { taskPriority } = values;
                                submit = handleSubmit;
                                return (
                                    <form onSubmit={handleSubmit} style={{ padding: 10 }}>
                                        <FormSpy
                                            subscription={{ values: true, valid: true }}
                                            onChange={state => {
                                                const { valid } = state;
                                                this.formIsValid(valid);
                                            }}
                                        />
                                        <OnChange name="propertyId">
                                            {value => {
                                                form.reset({
                                                    ...values,
                                                    taskDueDate: this.calculateDate(values)
                                                });
                                            }}
                                        </OnChange>

                                        <Field
                                            style={{ marginTop: 50 }}
                                            name="propertyId"
                                            label={t("add.Property")}
                                            className={classes.formControl}
                                            options={this.state.propertiesList.map(option => ({
                                                value: option.propertyId,
                                                label: option.propertyName
                                            }))}
                                            validate={required}
                                            component={renderSelect}
                                            classes={classes}
                                        />

                                        <Field
                                            name="taskDueDate"
                                            label={t("add.TaskDue")}
                                            checkIsSpecialDate={this.checkIsSpecialDate}
                                            taskPriority={taskPriority}
                                            component={renderDatePicker}
                                            classes={classes}
                                        />

                                        <Field name="guestInformed" label={t("add.GuestInformed")} type="checkbox" className={classes.formControl} component={renderCheckbox} />

                                        <FieldArray name="tasks">
                                            {({ fields }) => (
                                                <div>
                                                    {fields.map((items, index) => (
                                                        <div key={index} style={{ paddingTop: 5 }}>
                                                            <div>
                                                                <Field
                                                                    name={`${items}.taskDesc`}
                                                                    label={t("add.TaskDesc")}
                                                                    className={classes.formControl}
                                                                    multiline
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    validate={required}
                                                                    component={TextField}
                                                                />

                                                                <Field
                                                                    name={`${items}.taskPriority`}
                                                                    label={t("add.TaskPriority")}
                                                                    defaultValue={"0"}
                                                                    className={classes.formControl}
                                                                    options={[
                                                                        {
                                                                            value: "0",
                                                                            label: t("add.TaskPriorityLow")
                                                                        },
                                                                        {
                                                                            value: "1",
                                                                            label: t("add.TaskPriorityMedium")
                                                                        },
                                                                        {
                                                                            value: "2",
                                                                            label: t("add.TaskPriorityHigh")
                                                                        }
                                                                    ]}
                                                                    classes={classes}
                                                                    component={renderSelect}
                                                                />

                                                                <Field t={t} classes={classes} mutators={mutators} component={renderFileUploader} index={index} name={`${items}.taskFiles`} />
                                                            </div>

                                                            <Button
                                                                style={{
                                                                    marginTop: 10
                                                                }}
                                                                size="small"
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => fields.remove(index)}
                                                            >
                                                                {t("add.RemoveTask")}
                                                            </Button>
                                                        </div>
                                                    ))}
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        style={{
                                                            marginTop: 10
                                                        }}
                                                        size="small"
                                                        onClick={() =>
                                                            fields.push({
                                                                taskDesc: ""
                                                            })
                                                        }
                                                    >
                                                        {t("add.AddTask")}
                                                    </Button>
                                                </div>
                                            )}
                                        </FieldArray>
                                    </form>
                                );
                            }}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

AddMultiple.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withSnackbar(withStyles(styles, { withTheme: true })(AddMultiple))));
