import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import withAuth from "../components/auth/withAuth";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Fab from "@material-ui/core/Fab";
import FilterList from "@material-ui/icons/FilterList";
import ReactSelect from "react-select";

import gbLocale from "@fullcalendar/core/locales/en-gb";
import esLocale from "@fullcalendar/core/locales/es";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
// import "@fullcalendar/list/main.css";

import { withTranslation } from "react-multi-lang";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router";

import fetchClient from "../components/utils/fetchClient";

function Select({ defaultValue, options, ...otherProps }) {
    return (
        <ReactSelect
            defaultValue={
                defaultValue && {
                    value: defaultValue,
                    label: options.find(o => o.value === defaultValue).label
                }
            }
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: "#f8a34c",
                    primary50: "#f79632",
                    primary75: "#f68919",
                    primary: "#f57c00"
                }
            })}
            options={options}
            {...otherProps}
        />
    );
}

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: 10
    },
    calendar: {
        margin: 10
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing()
    },
    flex: {
        flex: 1
    },
    appBar: {
        position: "relative"
    },
    button: {
        marginLeft: 10,
        marginBottom: 5
    },
    iconsView: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center"
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "left"
        }
    },
    iconsNav: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center"
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "right"
        }
    },
    drawer: {
        width: 250
    },
    drawerInput: {
        margin: theme.spacing()
    },
    fab: {
        zIndex: 100,
        margin: theme.spacing(1),
        top: "auto",
        right: 10,
        bottom: 10,
        left: "auto",
        position: "fixed"
    }
});

const localeMap = {
    en: gbLocale,
    es: esLocale
};

class Calendar extends React.Component {
    calendarComponentRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            task: [],
            propertiesList: [],
            categoriesList: [],
            usersList: [],
            isLoading: false,
            isDisabled: false,
            // currentLang: getLanguage(),
            filters: {
                viewTaskType: 0,
                viewAssignedTo: 0
            },
            drawerOpen: false,
            filtersCount: 0
        };

        this.fetchEvents = this.fetchEvents.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);

        var maViewConfig = JSON.parse(localStorage.getItem("maViewConfig"));
        if (maViewConfig) {
            Object.keys(maViewConfig).forEach(key => {
                this.state.filters[key] = maViewConfig[key];
            });
        }
    }

    async componentDidMount() {
        let users = await this.fetchUsers();

        this.setState({
            usersList: users
        });
    }

    fetchEvents = (info, successCallback, failureCallback) => {
        var self = this;

        // if (self.state.isLoading) {
        fetchClient
            .post("/ma/calendar", {
                start: info.startStr,
                end: info.endStr,
                viewTaskType: self.state.filters.viewTaskType,
                viewAssignedTo: self.state.filters.viewAssignedTo
            })
            .then(function (response) {
                successCallback(response.data.data);

                self.setState({
                    filtersCount: self.state.filters.viewTaskType !== 0 || self.state.filters.viewAssignedTo !== 0 ? 1 : 0
                });
            })
            .catch(function (error) {
                failureCallback(error);
                console.log(error);
            });
        // }
    };

    async fetchUsers() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/users")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    handleSelect = (val, event) => {
        this.setState(
            {
                isLoading: true,
                filters: {
                    ...this.state.filters,
                    [event.name]: val ? val.value : 0
                },
                drawerOpen: false
            },
            () => {
                localStorage.setItem("maViewConfig", JSON.stringify(this.state.filters));

                let calendarApi = this.calendarComponentRef.current.getApi();
                calendarApi.refetchEvents();
            }
        );
    };

    handleDateChange = val => {
        var self = this;
        self.setState({ isDisabled: true });

        fetchClient
            .post("/ma/date-multiple", {
                propertyId: val.event.extendedProps.propertyId,
                taskDueDate: val.event.startStr
            })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    saveChanges(values) {
        var self = this;
        self.setState({ isDisabled: true });

        fetchClient
            .post("/ma/update/" + self.props.match.params.id, values)
            .then(function (response) {
                self.setState({ isDisabled: false });

                const backTo = localStorage.getItem("maBackTo");
                if (backTo) {
                    localStorage.removeItem("maBackTo");
                    self.props.history.push(backTo);
                } else {
                    self.props.history.push("/");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    calendarPrev = () => {
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.prev();
    };

    calendarNext = () => {
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.next();
    };

    calendarToday = () => {
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.today();
    };

    changeView = view => {
        var self = this;
        let calendarApi = self.calendarComponentRef.current.getApi();
        calendarApi.changeView(view);
    };

    toggleDrawer = open => () => {
        this.setState({
            drawerOpen: open
        });
    };

    render() {
        const { classes, t } = this.props;
        const locale = localeMap[this.state.currentLang];

        return (
            <div className={classes.root}>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading ? t("titles.Calendar") : t("titles.Loading") + "..."}
                        </Typography>
                        <IconButton onClick={this.toggleDrawer(true)} color="inherit">
                            <Badge badgeContent={this.state.filtersCount} color="secondary">
                                <FilterList />
                            </Badge>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {this.state.isLoading && <LinearProgress color="secondary" />}
                <div className={classes.content}>
                    <Grid container>
                        <Grid item xs={12} md={6} className={classes.iconsView}>
                            <Button onClick={() => this.changeView("dayGridMonth")} className={classes.button}>
                                {t("calendar.Month")}
                            </Button>
                            <Button onClick={() => this.changeView("timeGridWeek")} className={classes.button}>
                                {t("calendar.Week")}
                            </Button>
                            <Button onClick={() => this.changeView("timeGridDay")} className={classes.button}>
                                {t("calendar.Day")}
                            </Button>
                            <Button onClick={() => this.changeView("listDay")} className={classes.button}>
                                {t("calendar.Agenda")}
                            </Button>
                        </Grid>
                    </Grid>

                    <div className={classes.calendar}>
                        <FullCalendar
                            height="auto"
                            scrollTime="08:00:00"
                            locale={locale}
                            titleFormat={{ year: "2-digit", month: "numeric", day: "numeric" }}
                            defaultView="dayGridMonth"
                            weekends={true}
                            loading={status => this.setState({ isLoading: status })}
                            buttonText={{
                                today: t("calendar.Today"),
                                month: t("calendar.Month"),
                                week: t("calendar.Week"),
                                day: t("calendar.Day"),
                                list: t("calendar.Agenda")
                            }}
                            slotDuration={{ hours: 1 }}
                            slotLabelFormat={{
                                hour: "2-digit",
                                hour12: false
                            }}
                            header={{
                                left: "",
                                center: "title",
                                right: ""
                            }}
                            firstDay={1}
                            eventRender={this.eventRender}
                            editable={true}
                            eventLimit={true}
                            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, momentPlugin, listPlugin]}
                            ref={this.calendarComponentRef}
                            events={this.fetchEvents}
                            eventTimeFormat={{
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false
                            }}
                            eventDrop={this.handleDateChange}
                            eventClick={info => {
                                localStorage.setItem("maBackTo", "/calendar");
                                this.props.history.push("/pending/" + info.event.extendedProps.propertyId);
                                // console.log(info.event);
                            }}
                        />
                    </div>
                </div>
                <SwipeableDrawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} onOpen={this.toggleDrawer(true)}>
                    <div tabIndex={0} role="button" className={classes.drawer}>
                        <div className={classes.drawerInput}>
                            <Typography variant="h5" color="inherit">
                                {t("list.Filters")}
                            </Typography>
                            <Typography variant="h6" color="inherit">
                                {t("list.Type")}
                            </Typography>
                            <Select
                                isClearable
                                name="viewTaskType"
                                options={[
                                    {
                                        value: "1",
                                        label: t("list.TaskTypeNormal")
                                    },
                                    {
                                        value: "2",
                                        label: t("list.TaskTypePreventative")
                                    },
                                    {
                                        value: "4",
                                        label: t("list.TaskTypeLaunching")
                                    }
                                ]}
                                defaultValue={this.state.filters.viewTaskType}
                                classNamePrefix="react-select"
                                onChange={this.handleSelect}
                            />
                            <Typography variant="h6" color="inherit">
                                {t("list.AssignedTo")}
                            </Typography>

                            <Select
                                isClearable
                                name="viewAssignedTo"
                                options={this.state.usersList.map(option => ({
                                    value: option.userId,
                                    label: option.userName
                                }))}
                                defaultValue={this.state.filters.viewAssignedTo}
                                classNamePrefix="react-select"
                                onChange={this.handleSelect}
                            />
                        </div>
                    </div>
                </SwipeableDrawer>

                <Fab
                    color="primary"
                    aria-label={t("list.Add")}
                    className={classes.fab}
                    onClick={() => {
                        localStorage.setItem("maBackTo", "/calendar");
                        this.props.history.push("/new/");
                    }}
                >
                    <AddIcon />
                </Fab>
            </div>
        );
    }
}

Calendar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withRouter(withAuth(withSnackbar(withStyles(styles)(Calendar)))));
