import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import AddIconMultiple from "@material-ui/icons/PlaylistAdd";
import MenuIcon from "@material-ui/icons/Menu";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Drawer from "@material-ui/core/Drawer";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import ReactSelect from "react-select";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import StartedIcon from "@material-ui/icons/HourglassFull";
import PendingIcon from "@material-ui/icons/HourglassEmpty";
import AssignIcon from "@material-ui/icons/AssignmentInd";
import CompletedIcon from "@material-ui/icons/Done";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import RejectedIcon from "@material-ui/icons/Cancel";
import WaitingIcon from "@material-ui/icons/Timer";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import { Carousel } from "@stayunique/stayunique-ui";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withTranslation } from "react-multi-lang";

import axios from "axios";

import AuthService from "./auth/AuthService";
import withAuth from "./auth/withAuth";

import fetchClient from "./utils/fetchClient";
import isVideo from "./utils/isVideo";

const Auth = new AuthService();

function Select({ value, options, ...otherProps }) {
    return (
        <ReactSelect
            value={
                value && {
                    value: value,
                    label: options.find(o => o.value === value).label
                }
            }
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: "#f8a34c",
                    primary50: "#f79632",
                    primary75: "#f68919",
                    primary: "#f57c00"
                }
            })}
            options={options}
            {...otherProps}
        />
    );
}

const drawerWidth = 250;

const styles = theme => ({
    grow: {
        flexGrow: 1
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    headerIcon: {
        marginRight: 5,
        marginLeft: 5,
        [theme.breakpoints.down("md")]: {
            marginRight: 12,
            marginLeft: 12,
            marginTop: 12,
            marginBottom: 12
        }
    },
    drawerInput: {
        margin: theme.spacing()
    },
    divider: {
        backgroundColor: theme.palette.primary.main
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.up("xs")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(9),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit",
        width: "100%"
    },
    searchInput: {
        paddingTop: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingBottom: theme.spacing(),
        paddingLeft: theme.spacing(8),
        transition: theme.transitions.create("width"),
        width: "100%"
    },
    navTabs: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block"
        },
        position: "fixed",
        bottom: "0",
        width: `calc(100% - ${drawerWidth}px)`
    },
    list: {
        marginBottom: 25,
        [theme.breakpoints.up("sm")]: {
            marginBottom: 75
        }
    },
    button: {
        marginTop: 10
    },
    details: {
        flexDirection: "column"
    },
    fab: {
        display: "grid",
        [theme.breakpoints.up("md")]: {
            display: "none"
        },
        margin: theme.spacing(1),
        top: "auto",
        right: 10,
        bottom: 75,
        left: "auto",
        position: "fixed"
    },
    fabMultiple: {
        display: "grid",
        [theme.breakpoints.up("md")]: {
            display: "none"
        },
        margin: theme.spacing(1),
        top: "auto",
        right: 10,
        bottom: 10,
        left: "auto",
        position: "fixed"
    },
    formControl: {
        marginTop: theme.spacing(2)
    },
    filterBox: {
        backgroundColor: theme.palette.secondary.main,
        color: "white"
    },
    link: {
        marginBottom: 10
    },
    agendaList: {
        marginBottom: 40
    },
    root: {
        display: "flex"
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginRight: drawerWidth
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(3)
    },
    tab: {
        minWidth: 75,
        width: 75
    }
});

//Our order is not the same the apis
let indexes = {
    0: 6,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 7,
    7: 0
};

let index_flipped = Object.keys(indexes).reduce(function (obj, key) {
    obj[parseInt(indexes[key])] = parseInt(key);
    return obj;
}, {});

class TasksList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isInitialLoad: true,
            tasksList: [],
            tasksRemaining: 0,
            buildingsList: [],
            propertiesList: [],
            companiesList: [],
            usersList: [],
            filters: {
                viewType: 0,
                viewBuilding: 0,
                viewProperty: 0,
                viewSearch: "",
                viewTaskType: 0,
                viewTaskPriority: null,
                viewAssignedTo: 0,
                viewDueDate: null,
                viewCompletedDate: null,
                viewAvailableDate: null,
                viewMaintenanceCompany: 0
            },
            drawerOpen: false,
            filtersCount: 0,
            dateFiltersCount: 0,
            actionId: null,
            actionType: null,
            offset: 0,
            lightboxOpen: false,
            files: [],
            photoIndex: 0,
            isLoadingMore: false,
            front_loading: false,
            apt_loading: false,
            openEnabled: true,
            rejectDialogOpen: false,
            waitingDialogOpen: false,
            taskRejectedReason: "",
            taskWaitingDesc: "",
            viewingMore: false
        };

        var decoded = Auth.getProfile();
        this.state.profileId = decoded.profileId;
        this.state.accessLevel = decoded.accessLevel;
        this.state.userName = decoded.userName;

        this.logout = this.logout.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleTaskSearch = this.handleTaskSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.fetchTasks = this.fetchTasks.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.renderDateInput = this.renderDateInput.bind(this);
        this.clearDate = this.clearDate.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.openRejectDialog = this.openRejectDialog.bind(this);
        this.closeRejectDialog = this.closeRejectDialog.bind(this);
        this.handleWaiting = this.handleWaiting.bind(this);
        this.openWaitingDialog = this.openWaitingDialog.bind(this);
        this.closeWaitingDialog = this.closeWaitingDialog.bind(this);

        this.refetchTasksDebounced = this.debounce(this.refetchTasks, 500);

        var maViewConfig = JSON.parse(localStorage.getItem("maViewConfig"));
        if (maViewConfig) {
            Object.keys(maViewConfig).forEach(key => {
                this.state.filters[key] = maViewConfig[key];
            });
        }

        if (this.props.history.location && this.props.history.location.search) {
            const query = queryString.parse(this.props.history.location.search);

            if (Object.prototype.hasOwnProperty.call(query, "taskId")) {
                let value = query["taskId"];
                this.state.filters.viewSearch = `taskId:${value}`;
            }
        }
    }

    debounce(fn, delay) {
        var timer = null;
        return function () {
            var context = this,
                args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args);
            }, delay);
        };
    }

    logout() {
        Auth.logout();
        this.props.history.replace("/login");
    }

    toggleDrawer = open => () => {
        this.setState({
            drawerOpen: open
        });

        this.handleMenuClose();
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleOpenDoor(id, type) {
        var self = this;

        let errors = {
            1: self.props.t("list.UnlockNoticeOccupied"),
            2: self.props.t("list.UnlockNoticeOutsideWindow"),
            3: self.props.t("list.UnlockNoticeOutsideSchedule")
        };

        if (this.timer) {
            clearTimeout(this.timer);
        }

        if (self.state.actionId === id && self.state.actionType === type) {
            self.setState(
                {
                    front_loading: type === 2 ? true : false,
                    apt_loading: type === 1 ? true : false,
                    openEnabled: false
                },
                () => {
                    fetchClient
                        .post("ma/smartlock/" + id, { doortype: type })
                        .then(function (response) {
                            self.setState({
                                front_loading: false,
                                apt_loading: false,
                                openEnabled: true,
                                actionId: null
                            });

                            if (response.data.success) {
                                self.props.enqueueSnackbar(self.props.t("list.LockOpened"), { variant: "success", preventDuplicate: true });
                            } else {
                                let error = self.props.t("list.LockError");
                                if (errors[response.data.data.errorCode]) {
                                    error = errors[response.data.data.errorCode];
                                }
                                self.props.enqueueSnackbar(error, { variant: "error", preventDuplicate: true });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            );
        } else {
            self.setState({
                actionId: id,
                actionType: type
            });
        }

        this.timer = setTimeout(() => {
            self.setState({
                actionId: null,
                actionType: null
            });
        }, 3000);
    }

    fetchTask(id) {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/view/" + id)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async setTaskStatus(event, id) {
        var self = this;
        let taskStatus = event.target.value;

        const taskToUpdate = await this.fetchTask(id);
        const hasWrongCosts = taskToUpdate.taskCosts.length > 0 ? taskToUpdate.taskCosts.some(({ purchaseCost, purchaseDesc }) => Number(purchaseCost) === 0 || !purchaseDesc) : true;
        // console.log(taskToUpdate);
        // console.log(hasWrongCosts);

        if (taskStatus === "2" && (!taskToUpdate.taskNote || hasWrongCosts)) {
            this.props.enqueueSnackbar(this.props.t("add.WrongCompletedTask"), { preventDuplicate: true, variant: "error", persist: true });
            return true;
        }

        fetchClient
            .patch("ma/task/" + id, {
                taskStatus: taskStatus
            })
            .then(function (response) {
                // let updatedList = self.state.tasksList.filter(task => {
                //     if (self.state.filters.viewType !== 0) {
                //         if (task.taskId === id && taskStatus !== task.taskStatus) {
                //             return null;
                //         }
                //     }

                //     return task;
                // });

                self.refetchTasks();
                // self.setState({ tasksList: updatedList });
                self.props.enqueueSnackbar(self.props.t("list.TaskStatusChanged"), { variant: "success", preventDuplicate: true });
            })
            .catch(function (error) {
                console.log(error);
                this.props.enqueueSnackbar(error.message, { preventDuplicate: true, variant: "error", persist: true });
            });
    }

    setTaskStatusAgenda(event, id) {
        var self = this;
        let taskStatus = event.target.value;

        fetchClient
            .patch("ma/task/" + id, {
                taskStatus: taskStatus
            })
            .then(function (response) {
                self.props.enqueueSnackbar(self.props.t("list.TaskStatusChanged"), { preventDuplicate: true });

                self.refetchTasks();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleDelete(id) {
        var self = this;

        if (self.state.actionId === id) {
            fetchClient
                .delete("ma/task/" + id)
                .then(function (response) {
                    self.setState({
                        actionId: null
                    });

                    let updatedList = self.state.tasksList.filter(task => {
                        if (task.taskId !== id) {
                            return task;
                        } else {
                            return null;
                        }
                    });

                    self.setState({
                        tasksList: updatedList
                    });

                    self.props.enqueueSnackbar(self.props.t("list.TaskDeleted"), { variant: "success", preventDuplicate: true });
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            self.setState({
                actionId: id
            });
        }

        setTimeout(() => {
            self.setState({
                actionId: null
            });
        }, 3000);
    }

    handleTextChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    openRejectDialog(task) {
        if (task.taskStatus !== 4) {
            this.setState({
                actionTask: task,
                rejectDialogOpen: true,
                taskRejectedReason: ""
            });
        } else {
            this.setState(
                {
                    actionTask: task
                },
                () => {
                    this.handleReject();
                }
            );
        }
    }

    closeRejectDialog() {
        this.setState({
            actionTask: null,
            rejectDialogOpen: false,
            taskRejectedReason: ""
        });
    }

    handleReject() {
        var self = this;

        const rejectStatus = self.state.actionTask.taskStatus !== 4;

        fetchClient
            .patch("ma/reject/" + self.state.actionTask.taskId, {
                taskRejected: rejectStatus,
                taskRejectedReason: self.state.taskRejectedReason
            })
            .then(function (response) {
                let updatedList = self.state.tasksList.filter(task => {
                    if (task.taskId !== self.state.actionTask.taskId) {
                        return task;
                    } else {
                        return null;
                    }
                });

                self.setState({
                    tasksList: updatedList
                });

                if (rejectStatus) {
                    self.props.enqueueSnackbar(self.props.t("list.TaskRejected"), { variant: "info", preventDuplicate: true });
                } else {
                    self.props.enqueueSnackbar(self.props.t("list.TaskUnRejected"), { variant: "info", preventDuplicate: true });
                }

                self.closeRejectDialog();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    openWaitingDialog(task) {
        if (task.taskStatus !== 3) {
            this.setState({
                actionTask: task,
                waitingDialogOpen: true,
                taskWaitingDesc: ""
            });
        } else {
            this.setState(
                {
                    actionTask: task
                },
                () => {
                    this.handleWaiting();
                }
            );
        }
    }

    closeWaitingDialog() {
        this.setState({
            actionTask: null,
            waitingDialogOpen: false,
            taskWaitingDesc: ""
        });
    }

    handleWaiting() {
        var self = this;

        const waitingStatus = self.state.actionTask.taskStatus !== 3;

        fetchClient
            .patch("ma/waiting/" + self.state.actionTask.taskId, {
                taskWaiting: waitingStatus,
                taskWaitingDesc: self.state.taskWaitingDesc
            })
            .then(function (response) {
                let updatedList = self.state.tasksList.filter(task => {
                    if (task.taskId !== self.state.actionTask.taskId) {
                        return task;
                    } else {
                        return null;
                    }
                });

                self.setState({
                    tasksList: updatedList
                });

                if (waitingStatus) {
                    self.props.enqueueSnackbar(self.props.t("list.MarkedTaskWaiting"), { variant: "info", preventDuplicate: true });
                } else {
                    self.props.enqueueSnackbar(self.props.t("list.MarkedTaskUnWaiting"), { variant: "info", preventDuplicate: true });
                }

                self.closeWaitingDialog();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async componentDidMount() {
        this.interval = setInterval(() => this.refetchTasks(), 60000);

        let properties = await this.fetchProperties();
        let buildings = await this.fetchBuildings();
        let companies = await this.fetchCompanies();
        let tasks = await this.fetchTasks();
        let users = await this.fetchUsers();
        let lastAction = localStorage.getItem("maLastAction");

        this.setState({
            propertiesList: properties,
            buildingsList: buildings,
            companiesList: companies,
            tasksList: tasks.data,
            usersList: users,
            tasksRemaining: tasks.total - tasks.data.length,
            filtersCount: this.countFilters(),
            dateFiltersCount: this.countDateFilters(),
            isLoading: false,
            isInitialLoad: false,
            lastAction: lastAction
        });

        localStorage.removeItem("maLastAction");
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    isTimeToNotify() {
        const today = moment().format("YYYY-MM-DD");

        const reminderShown = localStorage.getItem("reminderShown");

        if (reminderShown && reminderShown === today) {
            return false;
        }

        localStorage.setItem("reminderShown", today);

        const time = moment();
        const startTime = moment("17:30:00", "HH:mm:ss");

        if (time.isAfter(startTime)) {
            return true;
        } else {
            return false;
        }
    }

    async refetchTasks() {
        this.props.enqueueSnackbar(this.props.t("list.UpdatingTasksList"), { variant: "info" });

        if (this.isTimeToNotify() && this.state.accessLevel < 4) {
            this.props.enqueueSnackbar(this.props.t("list.CloseTasksMessage"), { variant: "info", preventDuplicate: true });
        }

        if (!this.state.viewingMore) {
            let tasks = await this.fetchTasks();

            this.setState({
                tasksList: tasks.data,
                tasksRemaining: tasks.total - tasks.data.length,
                filtersCount: this.countFilters(),
                dateFiltersCount: this.countDateFilters(),
                isLoading: false
            });
        }
    }

    countFilters() {
        let count = 0;
        Object.keys(this.state.filters).forEach(key => {
            if (key !== "viewSearch" && key !== "viewCompletedDate" && key !== "viewAvailableDate") {
                if ((isNaN(this.state.filters[key]) && this.state.filters[key] !== "0") || (!isNaN(this.state.filters[key]) && this.state.filters[key] > 0)) {
                    count++;
                }
            }
        });

        return count;
    }

    countDateFilters() {
        let count = 0;
        Object.keys(this.state.filters).forEach(key => {
            if (key === "viewCompletedDate" || key === "viewAvailableDate") {
                if ((isNaN(this.state.filters[key]) && this.state.filters[key] !== "0") || (!isNaN(this.state.filters[key]) && this.state.filters[key] > 0)) {
                    count++;
                }
            }
        });

        return count;
    }

    async fetchTasks(offset = 0) {
        var self = this;

        if (self.state.source) {
            self.state.source.cancel("Navigation change");
        }

        const CancelToken = axios.CancelToken;
        self.state.source = CancelToken.source();

        return new Promise(function (resolve, reject) {
            fetchClient
                .post("ma/list?offset=" + offset, self.state.filters, { cancelToken: self.state.source.token })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                });
        });
    }

    async fetchProperties() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/properties")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchBuildings() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("buildings")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchCompanies() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/companies")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchUsers() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/users")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    clearSearch() {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    viewSearch: ""
                }
            },
            () => {
                localStorage.setItem("maViewConfig", JSON.stringify(this.state.filters));

                this.setState({
                    isLoading: true,
                    viewingMore: false
                });

                this.refetchTasks();
            }
        );
    }

    handleTaskSearch = event => {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    viewSearch: event.target.value
                },
                viewingMore: false,
                isLoading: true
            },
            () => {
                localStorage.setItem("maViewConfig", JSON.stringify(this.state.filters));

                this.refetchTasksDebounced();
            }
        );
    };

    handleTabChange = (event, tabIndex) => {
        var index = indexes[tabIndex];

        this.setState(
            {
                isLoading: true,
                viewingMore: false,
                filters: { ...this.state.filters, viewType: index, viewAvailableDate: null, viewCompletedDate: null }
            },
            () => {
                localStorage.setItem("maViewConfig", JSON.stringify(this.state.filters));
                this.refetchTasks();
            }
        );
    };

    async loadMore() {
        let newOffset = parseInt(this.state.offset) + 25;
        this.setState({ offset: newOffset, isLoadingMore: true, viewingMore: true });

        let tasks = await this.fetchTasks(newOffset);

        this.setState({
            tasksRemaining: tasks.total - this.state.tasksList.length - tasks.data.length,
            tasksList: [...this.state.tasksList, ...tasks.data],
            isLoading: false,
            isLoadingMore: false
        });
    }

    setPropertyId = propertyId => {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    viewProperty: propertyId,
                    viewAvailableDate: null,
                    viewCompletedDate: null
                }
            },
            () => {
                localStorage.setItem("maViewConfig", JSON.stringify(this.state.filters));
                this.refetchTasks();
            }
        );
    };

    handleSelect = (val, event) => {
        let value = null;
        if (event.name === "viewTaskPriority") {
            value = val ? val.value : null;
        } else {
            value = val ? val.value : 0;
        }

        this.setState(
            {
                isLoading: true,
                viewingMore: false,
                filters: {
                    ...this.state.filters,
                    [event.name]: value,
                    viewAvailableDate: null,
                    viewCompletedDate: null
                },
                drawerOpen: false
            },
            () => {
                localStorage.setItem("maViewConfig", JSON.stringify(this.state.filters));
                this.handleMenuClose();
                this.refetchTasks();
            }
        );
    };

    handleDateChange = (field, date) => {
        this.setState(
            {
                isLoading: true,
                viewingMore: false,
                filters: {
                    ...this.state.filters,
                    [field]: date ? moment(date).format("YYYY-MM-DD") : null
                },
                drawerOpen: false
            },
            () => {
                localStorage.setItem("maViewConfig", JSON.stringify(this.state.filters));
                this.handleMenuClose();
                this.refetchTasks();
            }
        );
    };

    openLightbox(files, index) {
        this.setState({
            lightboxOpen: true,
            files: files,
            photoIndex: index
        });
    }

    closeLightbox() {
        this.setState({
            lightboxOpen: false,
            files: [],
            photoIndex: 0
        });
    }

    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    clearDate = (field, event) => {
        event.stopPropagation();

        this.setState(
            {
                isLoading: true,
                filters: {
                    ...this.state.filters,
                    [field]: null
                }
            },
            () => {
                localStorage.setItem("maViewConfig", JSON.stringify(this.state.filters));
                this.refetchTasks();
            }
        );
    };

    renderDateInput(props) {
        return (
            <Input
                type="text"
                fullWidth
                onClick={props.onClick}
                id={props.id}
                value={props.value}
                disabled={props.disabled}
                classes={{
                    root: this.props.classes.inputRoot
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label={this.props.t("list.Clear")} onClick={e => this.clearDate(props.name, e)}>
                            <ClearIcon style={{ color: "white" }} />
                        </IconButton>
                    </InputAdornment>
                }
            />
        );
    }

    render() {
        const { anchorEl } = this.state;
        const { classes, t, window } = this.props;
        const isMenuOpen = Boolean(anchorEl);

        let prioritiesColors = {
            2: "red",
            1: "orange",
            0: "yellow"
        };

        let priorities = {
            0: t("list.PriorityLow"),
            1: t("list.PriorityMedium"),
            2: t("list.PriorityHigh")
        };

        let availablilityColors = {
            3: "purple",
            2: "orange",
            1: "green",
            0: "red"
        };

        const drawer = (
            <div className={classes.drawerInput}>
                <Typography variant="h5" color="inherit">
                    {t("list.Filters")} ({this.state.filtersCount})
                </Typography>

                {this.state.isInitialLoad ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Typography variant="h6" color="inherit">
                            {t("list.View")}
                        </Typography>
                        <Select
                            isClearable
                            name="viewType"
                            options={
                                this.state.accessLevel > 1
                                    ? [
                                          {
                                              value: 6,
                                              label: t("list.Agenda")
                                          },
                                          {
                                              value: 1,
                                              label: t("list.Pending")
                                          },
                                          {
                                              value: 2,
                                              label: t("list.PendingAssign")
                                          },
                                          {
                                              value: 3,
                                              label: t("list.Started")
                                          },
                                          {
                                              value: 4,
                                              label: t("list.Completed")
                                          },
                                          {
                                              value: 7,
                                              label: t("list.Rejected")
                                          },
                                          {
                                              value: 5,
                                              label: t("list.Waiting")
                                          }
                                      ]
                                    : [
                                          {
                                              value: 6,
                                              label: t("list.Available")
                                          },
                                          {
                                              value: 1,
                                              label: t("list.Pending")
                                          },
                                          {
                                              value: 3,
                                              label: t("list.Started")
                                          },
                                          {
                                              value: 4,
                                              label: t("list.Completed")
                                          },
                                          {
                                              value: 5,
                                              label: t("list.Waiting")
                                          },
                                          {
                                              value: 7,
                                              label: t("list.Rejected")
                                          }
                                      ]
                            }
                            value={this.state.filters.viewType}
                            classNamePrefix="react-select"
                            onChange={this.handleSelect}
                        />

                        <Typography variant="h6" color="inherit">
                            {t("list.MaintenanceCompany")}
                        </Typography>
                        <Select
                            isClearable
                            name="viewMaintenanceCompany"
                            options={this.state.companiesList.map(option => ({
                                value: option.companyId,
                                label: option.companyName
                            }))}
                            value={this.state.filters.viewMaintenanceCompany}
                            classNamePrefix="react-select"
                            onChange={this.handleSelect}
                        />

                        <Typography variant="h6" color="inherit">
                            {t("list.Building")}
                        </Typography>

                        <Select
                            isClearable
                            name="viewBuilding"
                            options={this.state.buildingsList.map(option => ({
                                value: option.buildingId,
                                label: option.buildingName
                            }))}
                            value={this.state.filters.viewBuilding}
                            classNamePrefix="react-select"
                            onChange={this.handleSelect}
                        />
                        <Typography variant="h6" color="inherit">
                            {t("list.Property")}
                        </Typography>

                        <Select
                            isClearable
                            name="viewProperty"
                            options={this.state.propertiesList.map(option => ({
                                value: option.propertyId,
                                label: option.propertyName
                            }))}
                            value={this.state.filters.viewProperty}
                            classNamePrefix="react-select"
                            onChange={this.handleSelect}
                        />
                        <Typography variant="h6" color="inherit">
                            {t("list.AssignedTo")}
                        </Typography>

                        <Select
                            isClearable
                            name="viewAssignedTo"
                            options={this.state.usersList.map(option => ({
                                value: option.userId,
                                label: option.userName
                            }))}
                            value={this.state.filters.viewAssignedTo}
                            classNamePrefix="react-select"
                            onChange={this.handleSelect}
                        />
                        <Typography variant="h6" color="inherit">
                            {t("list.Type")}
                        </Typography>

                        <Select
                            isClearable
                            name="viewTaskType"
                            options={[
                                {
                                    value: "1",
                                    label: t("edit.TaskTypeNormal")
                                },
                                {
                                    value: "2",
                                    label: t("edit.TaskTypePreventative")
                                },
                                {
                                    value: "4",
                                    label: t("edit.TaskTypeLaunching")
                                }
                            ]}
                            value={this.state.filters.viewTaskType}
                            classNamePrefix="react-select"
                            onChange={this.handleSelect}
                        />

                        <Typography variant="h6" color="inherit">
                            {t("list.Priority")}
                        </Typography>

                        <Select
                            isClearable
                            name="viewTaskPriority"
                            options={[
                                {
                                    value: "0",
                                    label: t("list.PriorityLow")
                                },
                                {
                                    value: "1",
                                    label: t("list.PriorityMedium")
                                },
                                {
                                    value: "2",
                                    label: t("list.PriorityHigh")
                                }
                            ]}
                            value={this.state.filters.viewTaskPriority}
                            classNamePrefix="react-select"
                            onChange={this.handleSelect}
                        />

                        <div style={{ marginTop: 15, marginLeft: 15 }}>
                            <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-circle" /> <span style={{ marginLeft: 8 }}>{t("list.PropertyNotes")}</span> <br />
                            <FontAwesomeIcon style={{ color: "orange" }} icon="pause" /> <span style={{ marginLeft: 10 }}>{t("list.Waiting")}</span> <br />
                            <FontAwesomeIcon style={{ color: "red" }} icon="ban" /> <span style={{ marginLeft: 8 }}>{t("list.TaskRejected")}</span>
                            <br />
                            <FontAwesomeIcon icon="door-open" /> <span style={{ marginLeft: 8 }}>{t("list.AutoCheckin")}</span>
                            <br />
                            <FontAwesomeIcon icon="key" /> <span style={{ marginLeft: 8 }}>{t("list.PropertyKeys")}</span>
                            <br />
                            <FontAwesomeIcon icon="clock" /> <span style={{ marginLeft: 10 }}>{t("list.LongTerm")}</span>
                            <br />
                            <span style={{ color: "green" }}>
                                <b>{t("list.Property")}</b>
                            </span>
                            <span> {t("list.Available")}</span>
                            <br />
                            <span style={{ color: "orange" }}>
                                <b>{t("list.Property")}</b>
                            </span>
                            <span> {t("list.AvailableCheckinToday")}</span>
                            <br />
                            <span style={{ color: "red" }}>
                                <b>{t("list.Property")}</b>
                            </span>
                            <span> {t("list.Unavailable")}</span>
                            <br />
                            <span style={{ color: "purple" }}>
                                <b>{t("list.Property")}</b>
                            </span>
                            <span> {t("list.Blocked")}</span>
                        </div>
                    </>
                )}
            </div>
        );

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem className={classes.sectionMobile} onClick={this.toggleDrawer(true)}>
                    <p>
                        {t("nav.Filter")} {this.state.filtersCount > 0 ? "(" + this.state.filtersCount + ")" : null}{" "}
                    </p>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        this.props.history.push("/settings/");
                    }}
                >
                    <p>{t("nav.Settings")}</p>
                </MenuItem>

                <MenuItem onClick={this.logout}>
                    <p>{t("nav.Logout", { param: this.state.userName })}</p>
                </MenuItem>
            </Menu>
        );

        const container = window !== undefined ? () => window().document.body : undefined;

        return (
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <FontAwesomeIcon icon="tools" className={classes.headerIcon} />
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            maintenanceApp
                        </Typography>
                        <div className={classes.grow}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    value={this.state.filters.viewSearch}
                                    onChange={this.handleTaskSearch}
                                    placeholder={t("list.Search") + "..."}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.searchInput
                                    }}
                                    endAdornment={
                                        this.state.filters.viewSearch.length > 0 && (
                                            <InputAdornment position="end">
                                                <IconButton aria-label={t("list.Clear")} onClick={this.clearSearch}>
                                                    <ClearIcon style={{ color: "white" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </div>
                        </div>

                        <div className={classes.sectionDesktop}>
                            <IconButton
                                onClick={() => {
                                    this.props.history.push("/calendar/");
                                }}
                                color="inherit"
                            >
                                <CalendarIcon />
                            </IconButton>
                            {this.state.accessLevel >= 3 && (
                                <IconButton
                                    onClick={() => {
                                        this.props.history.push("/new/");
                                    }}
                                    color="inherit"
                                >
                                    <AddIcon />
                                </IconButton>
                            )}
                            {this.state.accessLevel >= 3 && (
                                <IconButton
                                    onClick={() => {
                                        this.props.history.push("/new-multiple/");
                                    }}
                                    color="inherit"
                                >
                                    <AddIconMultiple />
                                </IconButton>
                            )}
                        </div>
                        <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.handleMenuOpen} color="inherit">
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                    <Accordion className={classes.filterBox}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon color="inherit" />} aria-controls="filters" id="filters">
                            <Typography className={classes.heading}>
                                {t("list.DateFilters")} {this.state.dateFiltersCount > 0 && <span>({this.state.dateFiltersCount})</span>}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}>
                            <Grid container spacing={2}>
                                {(this.state.filters.viewType === 0 || (this.state.filters.viewType > 0 && this.state.filters.viewType <= 6)) && (
                                    <Grid item xs={12} md>
                                        <Typography variant="body1" color="inherit">
                                            {t("list.DueDate")}
                                        </Typography>
                                        <DatePicker
                                            autoOk={true}
                                            value={this.state.filters.viewDueDate}
                                            clearable
                                            format="DD/MM/YYYY"
                                            name="viewDueDate"
                                            classes={classes.inputRoot}
                                            TextFieldComponent={this.renderDateInput}
                                            onChange={e => this.handleDateChange("viewDueDate", e)}
                                        />
                                    </Grid>
                                )}
                                {(this.state.filters.viewType === 0 || (this.state.filters.viewType > 3 && this.state.filters.viewType < 6)) && (
                                    <Grid item xs={12} md>
                                        <Typography variant="body1" color="inherit">
                                            {t("list.CompletedDate")}
                                        </Typography>
                                        <DatePicker
                                            autoOk={true}
                                            value={this.state.filters.viewCompletedDate}
                                            clearable
                                            format="DD/MM/YYYY"
                                            name="viewCompletedDate"
                                            classes={classes.inputRoot}
                                            TextFieldComponent={this.renderDateInput}
                                            onChange={e => this.handleDateChange("viewCompletedDate", e)}
                                        />
                                    </Grid>
                                )}
                                {(this.state.filters.viewType === 6 || (this.state.filters.viewType > 0 && this.state.filters.viewType < 4)) && (
                                    <Grid item xs={12} md>
                                        <Typography variant="body1" color="inherit">
                                            {t("list.AvailableDate")}
                                        </Typography>

                                        <DatePicker
                                            autoOk={true}
                                            value={this.state.filters.viewAvailableDate}
                                            clearable
                                            name="viewAvailableDate"
                                            classes={classes.inputRoot}
                                            TextFieldComponent={this.renderDateInput}
                                            format="DD/MM/YYYY"
                                            onChange={e => this.handleDateChange("viewAvailableDate", e)}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </AppBar>

                <main className={classes.content}>
                    <div className={classes.toolbar} />

                    <Carousel
                        open={this.state.lightboxOpen}
                        index={this.state.photoIndex}
                        items={this.state.files}
                        onClose={() => {
                            this.setState({
                                lightboxOpen: false,
                                files: [],
                                photoIndex: 0
                            });
                        }}
                    />

                    {renderMenu}

                    {this.state.isLoading && <LinearProgress color="secondary" />}
                    <List className={classes.list}>
                        {!this.state.isLoading &&
                            this.state.tasksList.map(task => {
                                return (
                                    <div key={this.state.filters.viewType !== 6 ? task.taskId : task.propertyId}>
                                        <Accordion defaultExpanded={this.state.lastAction === (this.state.filters.viewType !== 6 ? task.taskId : task.propertyId)}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography
                                                    style={
                                                        task.taskStatus === 2
                                                            ? {
                                                                  textDecoration: "line-through",
                                                                  color: "grey"
                                                              }
                                                            : null
                                                    }
                                                >
                                                    {this.state.filters.viewType === 6 ? (
                                                        <>
                                                            <span className="dot" style={{ backgroundColor: prioritiesColors[task.taskPriority] }}></span>{" "}
                                                            <b style={{ color: availablilityColors[task.isAvailable] }}>{task.propertyName}</b>{" "}
                                                            {task.isAvailable >= 1 ? t("list.Available") : t("list.Unavailable")}
                                                            {task.checkinData.arrival && " (" + moment(task.checkinData.arrival).format("DD/MM/YYYY HH:mm")}
                                                            {task.checkinData.departure && " - " + moment(task.checkinData.departure).format("DD/MM/YYYY HH:mm") + ")"}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="dot" style={{ backgroundColor: prioritiesColors[task.taskPriority] }}></span> {task.taskDesc.substring(0, 150) + "..."}
                                                        </>
                                                    )}
                                                    {task.assignedToName && (
                                                        <span>
                                                            {" "}
                                                            <i>({task.assignedToName})</i>{" "}
                                                        </span>
                                                    )}
                                                    {task.maintenanceNotes && (
                                                        <span>
                                                            {" "}
                                                            <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-circle" />
                                                        </span>
                                                    )}
                                                    {task.apartmentTaskCount > 1 && (
                                                        <span>
                                                            {" "}
                                                            <FontAwesomeIcon onClick={() => this.setPropertyId(task.propertyId)} style={{ color: "orange" }} icon="tasks" /> {task.apartmentTaskCount}
                                                        </span>
                                                    )}
                                                    {task.taskStatus === 3 && task.taskWaitingDesc && (
                                                        <span>
                                                            {" "}
                                                            <FontAwesomeIcon style={{ color: "orange" }} icon="pause" />
                                                        </span>
                                                    )}
                                                    {task.taskStatus === 4 && (
                                                        <span>
                                                            {" "}
                                                            <FontAwesomeIcon style={{ color: "red" }} icon="ban" />
                                                        </span>
                                                    )}
                                                    {task.autoCheckin === 1 && (
                                                        <span>
                                                            {" "}
                                                            <FontAwesomeIcon icon="door-open" />
                                                        </span>
                                                    )}
                                                    {task.longTerm === 1 && (
                                                        <span>
                                                            {" "}
                                                            <FontAwesomeIcon icon="clock" />
                                                        </span>
                                                    )}
                                                    {task.numKeys && (
                                                        <span>
                                                            {" "}
                                                            <FontAwesomeIcon icon="key" /> {task.numKeys}
                                                        </span>
                                                    )}
                                                    <br />

                                                    {this.state.filters.viewType !== 6 && (
                                                        <>
                                                            <b style={{ color: availablilityColors[task.isAvailable] }}>{task.propertyName}</b>{" "}
                                                            {task.isAvailable >= 1 ? t("list.Available") : t("list.Unavailable")}
                                                            {task.checkinData.arrival && " (" + moment(task.checkinData.arrival).format("DD/MM/YYYY HH:mm")}
                                                            {task.checkinData.departure && " - " + moment(task.checkinData.departure).format("DD/MM/YYYY HH:mm") + ")"}
                                                        </>
                                                    )}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.details}>
                                                {this.state.filters.viewType !== 6 ? (
                                                    <>
                                                        <Typography>{task.taskDesc}</Typography>

                                                        {task.taskApprovalStatus === 0 && <Typography style={{ color: "orange", fontWeight: "bold" }}>{t("list.TaskPending")}</Typography>}
                                                        {task.taskApprovalStatus === 2 && <Typography style={{ color: "red", fontWeight: "bold" }}>{t("list.TaskRejected")}</Typography>}

                                                        <Typography>
                                                            {task.hasMaintenance === 0 && (
                                                                <span>
                                                                    <br />
                                                                    <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-triangle" /> <b>{t("list.NoMaintenance")}</b>
                                                                </span>
                                                            )}
                                                            <br />
                                                            <FontAwesomeIcon icon="home" /> {task.isAvailable >= 1 ? t("list.Available") : t("list.Unavailable")}
                                                            <br />
                                                            <FontAwesomeIcon icon="user-plus" /> {task.createdByName}
                                                            {task.companyName && (
                                                                <span>
                                                                    <br />
                                                                    <FontAwesomeIcon icon="briefcase" /> {task.companyName}
                                                                </span>
                                                            )}
                                                            <br />
                                                            {task.propertyAddress && (
                                                                <span>
                                                                    <FontAwesomeIcon icon="map-marker-alt" />{" "}
                                                                    <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(task.propertyAddress)}>
                                                                        {task.propertyAddress}, {task.propertyFloor} - {task.propertyDoor}
                                                                    </a>
                                                                </span>
                                                            )}
                                                            <br />
                                                            <FontAwesomeIcon icon="calendar-week" /> {moment(task.taskCreation).format("DD/MM/YYYY HH:mm")} (
                                                            {t("list.DaysAgo", { param: moment().diff(moment(task.taskCreation), "days") + 1 })})
                                                            <br />
                                                            {task.taskStatus > 1 && (
                                                                <span>
                                                                    <FontAwesomeIcon icon="calendar-check" /> {moment(task.taskCompletion).format("DD/MM/YYYY HH:mm")}
                                                                    <br />
                                                                </span>
                                                            )}
                                                            <FontAwesomeIcon icon="exclamation-circle" /> {priorities[task.taskPriority]} {t("list.Priority")}
                                                            <br />
                                                            {task.guestInformed === 1 ? (
                                                                <span>
                                                                    <FontAwesomeIcon icon="user-check" /> {t("list.GuestInformed")}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    <FontAwesomeIcon icon="user-times" /> {t("list.GuestNotInformed")}
                                                                </span>
                                                            )}
                                                            <br />
                                                            <span>
                                                                {task.taskDueDate && (
                                                                    <>
                                                                        <FontAwesomeIcon icon="clock" /> {moment(task.taskDueDate).format("DD/MM/YYYY HH:mm")} {"("}
                                                                        {task.taskScheduledDate ? (
                                                                            <>
                                                                                {t("list.Scheduled")} {moment(task.taskScheduledDate).format("DD/MM/YYYY HH:mm")}
                                                                            </>
                                                                        ) : (
                                                                            <a
                                                                                href={`https://calendly.com/stay-u-nique-maintenance/task?a1=${encodeURIComponent(
                                                                                    "Mira los partes pendientes en la App"
                                                                                )}&a2=${encodeURIComponent(
                                                                                    `${task.propertyName} (${task.propertyAddress}, ${task.propertyFloor} - ${task.propertyDoor})`
                                                                                )}&a5=${task.taskId}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                {t("list.ScheduleEvent")}
                                                                            </a>
                                                                        )}
                                                                        {")"}
                                                                    </>
                                                                )}
                                                            </span>
                                                            <br />
                                                            {task.assignedToName && (
                                                                <span>
                                                                    <FontAwesomeIcon icon="user-tag" /> {task.assignedToName}
                                                                    <br />
                                                                </span>
                                                            )}
                                                            {task.maintenanceNotes && (
                                                                <span>
                                                                    <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-circle" /> <b>{task.maintenanceNotes}</b>
                                                                    <br />
                                                                </span>
                                                            )}
                                                            {task.taskStatus === 3 && task.taskWaitingDesc && (
                                                                <span>
                                                                    <br />
                                                                    <FontAwesomeIcon style={{ color: "orange" }} icon="pause" /> {task.taskWaitingDesc}
                                                                </span>
                                                            )}
                                                            {task.taskStatus === 4 && task.taskRejectedReason && (
                                                                <span>
                                                                    <br />
                                                                    <FontAwesomeIcon style={{ color: "red" }} icon="ban" /> {task.taskRejectedReason}
                                                                </span>
                                                            )}
                                                            {task.taskNote && (
                                                                <span>
                                                                    <br />
                                                                    <FontAwesomeIcon style={{ color: "orange" }} icon="sticky-note" /> {task.taskNote}
                                                                </span>
                                                            )}
                                                        </Typography>
                                                        <div style={{ paddingTop: 10 }}>
                                                            {task.taskFiles
                                                                ? task.taskFiles.map((image, index) =>
                                                                      !isVideo(image) ? (
                                                                          <img
                                                                              style={{
                                                                                  paddingLeft: 2,
                                                                                  paddingRight: 2
                                                                              }}
                                                                              key={index}
                                                                              height="50"
                                                                              width="50"
                                                                              className="uploaded-image"
                                                                              src={image}
                                                                              alt={image.split("/").pop()}
                                                                              onClick={() => this.openLightbox(task.taskFiles, index)}
                                                                          />
                                                                      ) : (
                                                                          <OndemandVideoIcon
                                                                              style={{
                                                                                  paddingLeft: 2,
                                                                                  paddingRight: 2
                                                                              }}
                                                                              key={index}
                                                                              height="100"
                                                                              width="100"
                                                                              className="uploaded-image"
                                                                              alt={image.split("/").pop()}
                                                                              onClick={() => this.openLightbox(task.taskFiles, index)}
                                                                          />
                                                                      )
                                                                  )
                                                                : null}
                                                        </div>
                                                        <FormControl className={classes.formControl}>
                                                            <FormLabel component="legend">{t("list.Status")}</FormLabel>

                                                            <RadioGroup
                                                                name="status"
                                                                readOnly={true}
                                                                aria-label={t("list.Status")}
                                                                value={task.taskStatus}
                                                                onChange={e => {
                                                                    this.setTaskStatus(e, task.taskId);
                                                                }}
                                                            >
                                                                <FormControlLabel value={0} control={<Radio />} label={t("list.Pending")} />
                                                                <FormControlLabel value={1} control={<Radio />} label={t("list.Started")} />
                                                                <FormControlLabel value={2} control={<Radio />} label={t("list.Completed")} />
                                                            </RadioGroup>
                                                            <FormHelperText id="status-warning">{t("list.StatusChangeWarning")}</FormHelperText>
                                                        </FormControl>

                                                        <div className={classes.grow}>
                                                            {task.propertyHasSmartLock > 0 && (
                                                                <div className={classes.lock}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        style={
                                                                            this.state.actionId === task.propertyId && this.state.actionType === 2 && !this.state.front_loading
                                                                                ? {
                                                                                      backgroundColor: "red"
                                                                                  }
                                                                                : null
                                                                        }
                                                                        disabled={!this.state.openEnabled || task.propertyHasSmartLock === 1}
                                                                        onClick={() => {
                                                                            this.handleOpenDoor(task.propertyId, 2);
                                                                        }}
                                                                    >
                                                                        {this.state.front_loading ? (
                                                                            <CircularProgress size={24} />
                                                                        ) : this.state.actionId === task.propertyId && this.state.actionType !== 1 ? (
                                                                            t("list.PressAgain")
                                                                        ) : (
                                                                            t("list.FrontDoor")
                                                                        )}
                                                                    </Button>
                                                                    <br />
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        style={
                                                                            this.state.actionId === task.propertyId && this.state.actionType === 1 && !this.state.front_loading
                                                                                ? {
                                                                                      backgroundColor: "red"
                                                                                  }
                                                                                : null
                                                                        }
                                                                        disabled={!this.state.openEnabled || task.propertyHasSmartLock === 3}
                                                                        onClick={() => {
                                                                            this.handleOpenDoor(task.propertyId, 1);
                                                                        }}
                                                                    >
                                                                        {this.state.apt_loading ? (
                                                                            <CircularProgress size={24} />
                                                                        ) : this.state.actionId === task.propertyId && this.state.actionType !== 2 ? (
                                                                            t("list.PressAgain")
                                                                        ) : (
                                                                            t("list.AptDoor")
                                                                        )}
                                                                    </Button>
                                                                </div>
                                                            )}

                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.button}
                                                                onClick={() => {
                                                                    localStorage.setItem("maLastAction", task.taskId);
                                                                    this.props.history.push("/edit/" + task.taskId + "");
                                                                }}
                                                            >
                                                                {t("list.Edit")}
                                                            </Button>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.button}
                                                                style={{ marginLeft: 15 }}
                                                                onClick={() => {
                                                                    this.openRejectDialog(task);
                                                                }}
                                                            >
                                                                {t("list.Reject")}
                                                            </Button>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.button}
                                                                style={{ marginLeft: 15 }}
                                                                onClick={() => {
                                                                    this.openWaitingDialog(task);
                                                                }}
                                                            >
                                                                {t("list.Waiting")}
                                                            </Button>

                                                            {this.state.accessLevel > 2 && (
                                                                <>
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        style={
                                                                            this.state.actionId === task.taskId
                                                                                ? {
                                                                                      backgroundColor: "red",
                                                                                      marginLeft: 15
                                                                                  }
                                                                                : { marginLeft: 15 }
                                                                        }
                                                                        onClick={() => {
                                                                            this.handleDelete(task.taskId);
                                                                        }}
                                                                    >
                                                                        {t("list.Delete")}
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>
                                                            {task.maintenanceNotes && (
                                                                <p>
                                                                    <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-circle" /> <b>{task.maintenanceNotes}</b>
                                                                </p>
                                                            )}

                                                            {task.hasMaintenance === 0 && (
                                                                <p>
                                                                    <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-circle" /> <b>{t("list.NoMaintenance")}</b>
                                                                </p>
                                                            )}
                                                        </div>

                                                        {task.tasks.map((subtask, index) => (
                                                            <div className={classes.agendaList} key={index}>
                                                                <Typography>{subtask.taskDesc}</Typography>
                                                                <Typography>
                                                                    <br />
                                                                    <span>
                                                                        {subtask.taskDueDate && (
                                                                            <>
                                                                                <FontAwesomeIcon icon="clock" /> {moment(subtask.taskDueDate).format("DD/MM/YYYY HH:mm")} {"("}
                                                                                {subtask.taskScheduledDate ? (
                                                                                    <>
                                                                                        {t("list.Scheduled")} {moment(subtask.taskScheduledDate).format("DD/MM/YYYY HH:mm")}
                                                                                    </>
                                                                                ) : (
                                                                                    <a
                                                                                        href={`https://calendly.com/stay-u-nique-maintenance/task?a1=${encodeURIComponent(
                                                                                            subtask.taskDesc
                                                                                        )}&a2=${encodeURIComponent(
                                                                                            `${subtask.propertyName} (${subtask.propertyAddress}, ${subtask.propertyFloor} - ${subtask.propertyDoor})`
                                                                                        )}&a5=${subtask.taskId}`}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        {t("list.ScheduleEvent")}
                                                                                    </a>
                                                                                )}
                                                                                {")"}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                    <br />
                                                                    {subtask.assignedToName && (
                                                                        <span>
                                                                            <FontAwesomeIcon icon="user-tag" /> {subtask.assignedToName}
                                                                            <br />
                                                                        </span>
                                                                    )}
                                                                    <FontAwesomeIcon icon="user-plus" /> {subtask.createdByName}
                                                                    {subtask.companyName && (
                                                                        <span>
                                                                            <br />
                                                                            <FontAwesomeIcon icon="briefcase" /> {subtask.companyName}
                                                                        </span>
                                                                    )}
                                                                    <br />
                                                                    {task.propertyAddress && (
                                                                        <span>
                                                                            <FontAwesomeIcon icon="map-marker-alt" />{" "}
                                                                            <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(task.propertyAddress)}>
                                                                                {task.propertyAddress}, {task.propertyFloor} - {task.propertyDoor}
                                                                            </a>
                                                                        </span>
                                                                    )}
                                                                    <br />
                                                                    <FontAwesomeIcon icon="calendar-week" /> {moment(subtask.taskCreation).format("DD/MM/YYYY HH:mm")} (
                                                                    {t("list.DaysAgo", { param: moment().diff(moment(subtask.taskCreation), "days") + 1 })})
                                                                    <br />
                                                                    <FontAwesomeIcon icon="exclamation-circle" /> {priorities[subtask.taskPriority]} {t("list.Priority")}
                                                                    <br />
                                                                    {subtask.guestInformed === 1 ? (
                                                                        <span>
                                                                            <FontAwesomeIcon icon="user-check" /> {t("list.GuestInformed")}
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            <FontAwesomeIcon icon="user-times" /> {t("list.GuestNotInformed")}
                                                                        </span>
                                                                    )}
                                                                    {subtask.assignedToName && (
                                                                        <span>
                                                                            {" "}
                                                                            <FontAwesomeIcon icon="user-tag" /> {subtask.assignedToName}
                                                                            <br />
                                                                        </span>
                                                                    )}
                                                                    {subtask.maintenanceNotes && (
                                                                        <span>
                                                                            <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-circle" /> <b>{subtask.maintenanceNotes}</b>
                                                                            <br />
                                                                        </span>
                                                                    )}
                                                                    {subtask.taskStatus === 3 && subtask.taskWaitingDesc && (
                                                                        <span>
                                                                            <br />
                                                                            <FontAwesomeIcon style={{ color: "orange" }} icon="pause" /> {subtask.taskWaitingDesc}
                                                                        </span>
                                                                    )}
                                                                    {subtask.taskNote && (
                                                                        <span>
                                                                            <br />
                                                                            <FontAwesomeIcon style={{ color: "orange" }} icon="sticky-note" /> {subtask.taskNote}
                                                                        </span>
                                                                    )}
                                                                </Typography>

                                                                <div style={{ paddingTop: 10 }}>
                                                                    {subtask.taskFiles
                                                                        ? subtask.taskFiles.map((image, index) => (
                                                                              <img
                                                                                  style={{
                                                                                      paddingLeft: 2,
                                                                                      paddingRight: 2
                                                                                  }}
                                                                                  key={index}
                                                                                  height="50"
                                                                                  width="50"
                                                                                  className="uploaded-image"
                                                                                  src={!isVideo(image) ? image : require("../assets/video.png")}
                                                                                  alt={image.split("/").pop()}
                                                                                  onClick={() => this.openLightbox(subtask.taskFiles, index)}
                                                                              />
                                                                          ))
                                                                        : null}
                                                                </div>
                                                                <FormControl className={classes.formControl}>
                                                                    <FormLabel component="legend">{t("list.Status")}</FormLabel>
                                                                    <RadioGroup
                                                                        name="status"
                                                                        readOnly={true}
                                                                        aria-label={t("list.Status")}
                                                                        value={subtask.taskStatus}
                                                                        onChange={e => {
                                                                            this.setTaskStatusAgenda(e, subtask.taskId);
                                                                        }}
                                                                    >
                                                                        <FormControlLabel value={0} control={<Radio />} label={t("list.Pending")} />
                                                                        <FormControlLabel value={1} control={<Radio />} label={t("list.Started")} />
                                                                        <FormControlLabel value={2} control={<Radio />} label={t("list.Completed")} />
                                                                        <FormControlLabel value={3} control={<Radio />} label={t("list.Waiting")} />
                                                                    </RadioGroup>
                                                                    <FormHelperText id="status-warning">{t("list.StatusChangeWarning")}</FormHelperText>
                                                                </FormControl>
                                                                <div className={classes.grow}>
                                                                    {subtask.propertyHasSmartLock > 0 && (
                                                                        <div className={classes.lock}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                className={classes.button}
                                                                                style={
                                                                                    this.state.actionId === subtask.propertyId && this.state.actionType === 2 && !this.state.front_loading
                                                                                        ? {
                                                                                              backgroundColor: "red"
                                                                                          }
                                                                                        : null
                                                                                }
                                                                                disabled={!this.state.openEnabled || subtask.propertyHasSmartLock === 1}
                                                                                onClick={() => {
                                                                                    this.handleOpenDoor(subtask.propertyId, 2);
                                                                                }}
                                                                            >
                                                                                {this.state.front_loading ? (
                                                                                    <CircularProgress size={24} />
                                                                                ) : this.state.actionId === subtask.propertyId && this.state.actionType !== 1 ? (
                                                                                    t("list.PressAgain")
                                                                                ) : (
                                                                                    t("list.FrontDoor")
                                                                                )}
                                                                            </Button>
                                                                            <br />
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                className={classes.button}
                                                                                style={
                                                                                    this.state.actionId === subtask.propertyId && this.state.actionType === 1 && !this.state.front_loading
                                                                                        ? {
                                                                                              backgroundColor: "red"
                                                                                          }
                                                                                        : null
                                                                                }
                                                                                disabled={!this.state.openEnabled || subtask.propertyHasSmartLock === 3}
                                                                                onClick={() => {
                                                                                    this.handleOpenDoor(subtask.propertyId, 1);
                                                                                }}
                                                                            >
                                                                                {this.state.apt_loading ? (
                                                                                    <CircularProgress size={24} />
                                                                                ) : this.state.actionId === subtask.propertyId && this.state.actionType !== 2 ? (
                                                                                    t("list.PressAgain")
                                                                                ) : (
                                                                                    t("list.AptDoor")
                                                                                )}
                                                                            </Button>
                                                                        </div>
                                                                    )}

                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        onClick={() => {
                                                                            localStorage.setItem("maLastAction", subtask.propertyId);
                                                                            this.props.history.push("/edit/" + subtask.taskId + "");
                                                                        }}
                                                                    >
                                                                        {t("list.Edit")}
                                                                    </Button>
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        style={{ marginLeft: 15 }}
                                                                        onClick={() => {
                                                                            this.openRejectDialog(subtask);
                                                                        }}
                                                                    >
                                                                        {t("list.Reject")}
                                                                    </Button>
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        style={{ marginLeft: 15 }}
                                                                        onClick={() => {
                                                                            this.openWaitingDialog(subtask);
                                                                        }}
                                                                    >
                                                                        {t("list.Waiting")}
                                                                    </Button>

                                                                    {this.state.accessLevel > 2 && (
                                                                        <>
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                className={classes.button}
                                                                                style={
                                                                                    this.state.actionId === subtask.taskId
                                                                                        ? {
                                                                                              backgroundColor: "red",
                                                                                              marginLeft: 15
                                                                                          }
                                                                                        : { marginLeft: 15 }
                                                                                }
                                                                                onClick={() => {
                                                                                    this.handleDelete(subtask.taskId);
                                                                                }}
                                                                            >
                                                                                {t("list.Delete")}
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className={classes.divider} />
                                    </div>
                                );
                            })}

                        {!this.state.isLoading && this.state.tasksRemaining > 0 && (
                            <Grid container justifyContent="center" style={{ marginTop: 15 }}>
                                {this.state.isLoadingMore ? (
                                    <CircularProgress size={30} />
                                ) : (
                                    <Button onClick={() => this.loadMore()}>{t("list.LoadMore") + " (" + this.state.tasksRemaining + ")..."}</Button>
                                )}
                            </Grid>
                        )}
                    </List>

                    {this.state.accessLevel >= 3 && (
                        <Fab
                            color="primary"
                            aria-label={t("list.Add")}
                            className={classes.fab}
                            onClick={() => {
                                this.props.history.push("/new/");
                            }}
                        >
                            <AddIcon />
                        </Fab>
                    )}
                    {this.state.accessLevel >= 3 && (
                        <Fab
                            color="secondary"
                            aria-label={t("list.AddMultiple")}
                            className={classes.fabMultiple}
                            onClick={() => {
                                this.props.history.push("/new-multiple/");
                            }}
                        >
                            <AddIconMultiple />
                        </Fab>
                    )}

                    <Dialog open={this.state.rejectDialogOpen} onClose={this.closeRejectDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{t("list.RejectTask")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{t("list.RejectTaskDesc")}</DialogContentText>
                            <TextField
                                autoFocus
                                value={this.state.taskRejectedReason}
                                multiline
                                onChange={this.handleTextChange}
                                margin="dense"
                                name="taskRejectedReason"
                                label={t("list.RejectReason")}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeRejectDialog} color="primary">
                                {t("misc.Close")}
                            </Button>
                            <Button onClick={this.handleReject} color="primary" disabled={this.state.taskRejectedReason.length === 0}>
                                {t("list.Reject")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={this.state.waitingDialogOpen} onClose={this.closeWaitingDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{t("list.MarkWaiting")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{t("list.WaitingTaskDesc")}</DialogContentText>
                            <TextField
                                autoFocus
                                value={this.state.taskWaitingDesc}
                                multiline
                                onChange={this.handleTextChange}
                                margin="dense"
                                name="taskWaitingDesc"
                                label={t("list.TaskWaitingDesc")}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeWaitingDialog} color="primary">
                                {t("misc.Close")}
                            </Button>
                            <Button onClick={this.handleWaiting} color="primary" disabled={this.state.taskWaitingDesc.length === 0}>
                                {t("list.MarkWaiting")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <div className={classes.navTabs}>
                        <Paper square>
                            <Tabs
                                value={index_flipped[this.state.filters.viewType]}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label={t("list.SwitchView")}
                            >
                                <Tab icon={<CalendarTodayIcon />} label={t("list.Agenda")} />
                                <Tab icon={<PendingIcon />} label={t("list.Pending")} />
                                <Tab icon={<AssignIcon />} style={this.state.accessLevel > 1 ? null : { display: "none" }} label={t("list.PendingAssign")} />
                                <Tab icon={<StartedIcon />} label={t("list.Started")} />
                                <Tab icon={<CompletedIcon />} label={t("list.Completed")} />
                                <Tab icon={<WaitingIcon />} label={t("list.Waiting")} />
                                <Tab icon={<RejectedIcon />} label={t("list.Rejected")} />
                                <Tab icon={<AllInboxIcon />} label={t("list.All")} />
                            </Tabs>
                        </Paper>
                    </div>
                </main>

                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor="right"
                            open={this.state.drawerOpen}
                            onClose={() => this.toggleDrawer(false)}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            ModalProps={{
                                keepMounted: true // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open
                            anchor="right"
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        );
    }
}

TasksList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withRouter(withAuth(withSnackbar(withStyles(styles)(TasksList)))));
