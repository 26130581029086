import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { setDefaultTranslations, setDefaultLanguage, setLanguage } from "react-multi-lang";
import es from "./translations/es.json";
import en from "./translations/en.json";

setDefaultTranslations({ es, en });
setDefaultLanguage("es");

var maSettings = JSON.parse(localStorage.getItem("maSettings"));
if (maSettings) {
    if (maSettings.language) {
        setLanguage(maSettings.language);
    }
} else {
    localStorage.setItem("maSettings", JSON.stringify({ language: "en" }));
}

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider
            ref={notistackRef}
            action={key => (
                <IconButton style={{ color: "white" }} onClick={onClickDismiss(key)}>
                    <CloseIcon />
                </IconButton>
            )}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <App />
        </SnackbarProvider>
    </MuiPickersUtilsProvider>,
    document.getElementById("root")
);
