
export default function convertToBooleanValue(val) {

    let value = val;

    if (typeof value !== "boolean") {
        value = Boolean(parseInt(value));
    }

    return value;
}