import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal, ModalGateway } from "react-images";
import Carousel from "react-images";
import moment from "moment";
import { withSnackbar } from "notistack";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withTranslation } from "react-multi-lang";

import AuthService from "./auth/AuthService";
import withAuth from "./auth/withAuth";

import fetchClient from "./utils/fetchClient";
import fetchClientExternal from "./utils/fetchClientExternal";
import isVideo from "./utils/isVideo";

const Auth = new AuthService();

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    divider: {
        backgroundColor: theme.palette.primary.main
    },
    list: {
        marginBottom: 25,
        [theme.breakpoints.up("sm")]: {
            marginBottom: 75
        }
    },
    button: {
        marginTop: 10
    },
    details: {
        flexDirection: "column"
    },
    fab: {
        margin: theme.spacing(1),
        top: "auto",
        right: 10,
        bottom: 10,
        left: "auto",
        position: "fixed"
    },
    formControl: {
        marginTop: theme.spacing(2)
    }
});

class PendingTasksList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            tasksList: [],
            property: [],
            actionId: null,
            actionType: null,
            lightboxOpen: false,
            files: [],
            photoIndex: 0,
            front_loading: false,
            apt_loading: false,
            openEnabled: true
        };

        var decoded = Auth.getProfile();
        this.state.profileId = decoded.profileId;
        this.state.accessLevel = decoded.accessLevel;
        this.state.userName = decoded.userName;

        this.fetchTasks = this.fetchTasks.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    handleOpenDoor(id, type) {
        var self = this;

        let errors = {
            1: self.props.t("list.UnlockNoticeOccupied"),
            2: self.props.t("list.UnlockNoticeOutsideWindow"),
            3: self.props.t("list.UnlockNoticeOutsideSchedule")
        };

        if (this.timer) {
            clearTimeout(this.timer);
        }

        if (self.state.actionId === id && self.state.actionType === type) {
            self.setState(
                {
                    front_loading: type === 2 ? true : false,
                    apt_loading: type === 1 ? true : false,
                    openEnabled: false
                },
                () => {
                    fetchClient
                        .post("ma/smartlock/" + id, { doortype: type })
                        .then(function (response) {
                            self.setState({
                                front_loading: false,
                                apt_loading: false,
                                openEnabled: true,
                                actionId: null
                            });

                            if (response.data.success) {
                                self.props.enqueueSnackbar(self.props.t("list.LockOpened"), { preventDuplicate: true });
                            } else {
                                let error = self.props.t("list.LockError");
                                if (errors[response.data.data.errorCode]) {
                                    error = errors[response.data.data.errorCode];
                                }
                                self.props.enqueueSnackbar(error, { preventDuplicate: true });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            );
        } else {
            self.setState({
                actionId: id,
                actionType: type
            });
        }

        this.timer = setTimeout(() => {
            self.setState({
                actionId: null,
                actionType: null
            });
        }, 3000);
    }

    fetchTask(id) {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ma/view/" + id)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async setTaskStatus(event, id) {
        var self = this;
        let taskStatus = event.target.value;

        const taskToUpdate = await this.fetchTask(id);
        const hasWrongCosts = taskToUpdate.taskCosts.some(({ purchaseCost, purchaseDesc }) => Number(purchaseCost) === 0 || !purchaseDesc);

        if (taskStatus === "2" && (!taskToUpdate.taskNote || hasWrongCosts)) {
            this.props.enqueueSnackbar(this.props.t("add.WrongCompletedTask"), { preventDuplicate: true, variant: "error", persist: true });
            return true;
        }

        fetchClient
            .patch("ma/task/" + id, {
                taskStatus: taskStatus
            })
            .then(function (response) {
                let updatedList = self.state.tasksList.filter(task => {
                    if (task.taskId === id && taskStatus > 1) {
                        return null;
                    } else {
                        return task;
                    }
                });
                self.setState({ tasksList: updatedList });
                self.props.enqueueSnackbar(self.props.t("list.TaskStatusChanged"), { preventDuplicate: true });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleDelete(id) {
        var self = this;

        if (self.state.actionId === id) {
            fetchClient
                .delete("ma/task/" + id)
                .then(function (response) {
                    self.setState({
                        actionId: null
                    });

                    let updatedList = self.state.tasksList.filter(task => {
                        if (task.taskId !== id) {
                            return task;
                        } else {
                            return null;
                        }
                    });

                    self.setState({
                        tasksList: updatedList
                    });

                    self.props.enqueueSnackbar(self.props.t("list.TaskDeleted"), { preventDuplicate: true });
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            self.setState({
                actionId: id
            });
        }

        setTimeout(() => {
            self.setState({
                actionId: null
            });
        }, 3000);
    }

    handleReject(id, status) {
        var self = this;

        fetchClient
            .patch("ma/reject/" + id, {
                taskRejected: 1 - status
            })
            .then(function (response) {
                let updatedList = self.state.tasksList.filter(task => {
                    if (task.taskId === id) {
                        if (task.taskRejected === 1) {
                            task.taskRejected = 0;
                        } else {
                            task.taskRejected = 1;
                        }

                        return task;
                    }

                    return task;
                });

                self.setState({
                    tasksList: updatedList
                });

                self.props.enqueueSnackbar(self.props.t("list.TaskRejected"), { preventDuplicate: true });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleTranslate = (id, val) => {
        var self = this;
        fetchClientExternal
            .get(
                "https://translate.yandex.net/api/v1.5/tr.json/translate?key=trnsl.1.1.20190202T145045Z.0794cd876d4bbdbd.f80a395ca104e48daf39091c1b7ee1b7e2de4277&text=" +
                    encodeURIComponent(val) +
                    "&lang=en"
            )
            .then(function (response) {
                let translation = response.data.text[0];

                if (translation) {
                    let updatedList = self.state.tasksList.filter(task => {
                        if (task.taskId === id) {
                            task.taskDesc = translation;
                            return task;
                        }
                        return task;
                    });

                    self.setState({
                        tasksList: updatedList
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async componentDidMount() {
        let tasks = await this.fetchTasks();
        let property = await this.fetchProperty();

        this.setState({
            tasksList: tasks.data,
            property: property.data,
            isLoading: false
        });
    }

    async fetchTasks() {
        var self = this;
        return new Promise(function (resolve, reject) {
            fetchClient
                .post("ma/list", { viewProperty: self.props.match.params.id, viewType: 1 }) //viewType = 1 -> Shows pending tasks
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchProperty() {
        var self = this;
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("properties/" + self.props.match.params.id)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    handleBack() {
        const backTo = localStorage.getItem("maBackTo");
        if (backTo) {
            localStorage.removeItem("maBackTo");
            this.props.history.push(backTo);
        } else {
            this.props.history.push("/");
        }
    }

    openLightbox(files, index) {
        this.setState({
            lightboxOpen: true,
            files: files,
            photoIndex: index
        });
    }

    closeLightbox() {
        this.setState({
            lightboxOpen: false,
            files: [],
            photoIndex: 0
        });
    }

    render() {
        const { classes, t } = this.props;

        let prioritiesColors = {
            2: "red",
            1: "orange",
            0: "yellow"
        };

        let priorities = {
            0: t("list.PriorityLow"),
            1: t("list.PriorityMedium"),
            2: t("list.PriorityHigh")
        };

        let availablilityColors = {
            3: "purple",
            2: "orange",
            1: "green",
            0: "red"
        };

        return (
            <div className={classes.root}>
                <ModalGateway>
                    {this.state.lightboxOpen ? (
                        <Modal
                            onClose={this.closeLightbox}
                            styles={{
                                blanket: (base, state) => ({ ...base, zIndex: 1100 }),
                                positioner: (base, state) => ({ ...base, zIndex: 1110 }),
                                dialog: (base, state) => ({ ...base, zIndex: 1120 })
                            }}
                        >
                            <Carousel
                                currentIndex={this.state.photoIndex}
                                views={this.state.files.map(file => ({
                                    src: file
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton onClick={this.handleBack} className={classes.menuButton} color="inherit" aria-label={t("misc.Back")}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading ? t("titles.ViewPendingTasks", { param: this.state.property.propertyName }) : t("titles.Loading") + "..."}
                        </Typography>
                    </Toolbar>
                </AppBar>

                {this.state.isLoading && <LinearProgress color="secondary" />}

                <List className={classes.list}>
                    {!this.state.isLoading &&
                        this.state.tasksList.map(task => {
                            return (
                                <div key={task.taskId}>
                                    <ExpansionPanel key={task.propertyId}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography
                                                style={
                                                    task.taskStatus >= 2
                                                        ? {
                                                              textDecoration: "line-through",
                                                              color: "grey"
                                                          }
                                                        : null
                                                }
                                            >
                                                <span className="dot" style={{ backgroundColor: prioritiesColors[task.taskPriority] }}></span> {task.taskDesc.substring(0, 150) + "..."}
                                                {task.assignedToName && (
                                                    <span>
                                                        {" "}
                                                        <i>({task.assignedToName})</i>{" "}
                                                    </span>
                                                )}
                                                {task.maintenanceNotes && (
                                                    <span>
                                                        {" "}
                                                        <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-circle" />
                                                    </span>
                                                )}
                                                {task.taskWaiting === 1 && task.taskWaitingDesc && (
                                                    <span>
                                                        {" "}
                                                        <FontAwesomeIcon style={{ color: "orange" }} icon="pause" />
                                                    </span>
                                                )}
                                                {task.taskRejected === 1 && (
                                                    <span>
                                                        {" "}
                                                        <FontAwesomeIcon style={{ color: "red" }} icon="ban" />
                                                    </span>
                                                )}
                                                {task.autoCheckin === 1 && (
                                                    <span>
                                                        {" "}
                                                        <FontAwesomeIcon icon="door-open" />
                                                    </span>
                                                )}
                                                <br />
                                                <b style={{ color: availablilityColors[task.isAvailable] }}>{task.propertyName}</b> -{" "}
                                                {task.isAvailable >= 1 ? t("list.Available") : t("list.Unavailable")}
                                                {task.checkinData.arrival && " (" + moment(task.checkinData.arrival).format("DD/MM/YYYY HH:mm")}
                                                {task.checkinData.departure && " - " + moment(task.checkinData.departure).format("DD/MM/YYYY HH:mm") + ")"}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.details}>
                                            <Typography>
                                                {task.taskDesc}{" "}
                                                <FontAwesomeIcon
                                                    icon="language"
                                                    onClick={() => {
                                                        this.handleTranslate(task.taskId, task.taskDesc);
                                                    }}
                                                />
                                            </Typography>
                                            <Typography>
                                                {task.hasMaintenance === 0 && (
                                                    <span>
                                                        <br />
                                                        <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-triangle" /> <b>{t("list.NoMaintenance")}</b>
                                                    </span>
                                                )}
                                                <br />
                                                <FontAwesomeIcon icon="home" /> {task.isAvailable >= 1 ? t("list.Available") : t("list.Unavailable")}
                                                <br />
                                                <FontAwesomeIcon icon="user-plus" /> {task.createdByName}
                                                {task.companyName && (
                                                    <span>
                                                        <br />
                                                        <FontAwesomeIcon icon="briefcase" /> {task.companyName}
                                                    </span>
                                                )}
                                                <br />
                                                <FontAwesomeIcon icon="calendar-week" /> {moment(task.taskCreation).format("DD/MM/YYYY HH:mm")} (
                                                {t("list.DaysAgo", { param: moment().diff(moment(task.taskCreation), "days") + 1 })})
                                                <br />
                                                {task.taskStatus > 1 && (
                                                    <span>
                                                        <FontAwesomeIcon icon="calendar-check" /> {moment(task.taskCompletion).format("DD/MM/YYYY HH:mm")}
                                                        <br />
                                                    </span>
                                                )}
                                                <FontAwesomeIcon icon="exclamation-circle" /> {priorities[task.taskPriority]} {t("list.Priority")}
                                                <br />
                                                {task.guestInformed === 1 ? (
                                                    <span>
                                                        <FontAwesomeIcon icon="user-check" /> {t("list.GuestInformed")}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <FontAwesomeIcon icon="user-times" /> {t("list.GuestNotInformed")}
                                                    </span>
                                                )}
                                                <br />
                                                {task.taskDueDate && (
                                                    <span>
                                                        <FontAwesomeIcon icon="clock" /> {moment(task.taskDueDate).format("DD/MM/YYYY HH:mm")}
                                                        <br />
                                                    </span>
                                                )}
                                                {task.assignedToName && (
                                                    <span>
                                                        <FontAwesomeIcon icon="user-tag" /> {task.assignedToName}
                                                        <br />
                                                    </span>
                                                )}
                                                {task.maintenanceNotes && (
                                                    <span>
                                                        <FontAwesomeIcon style={{ color: "red" }} icon="exclamation-circle" /> <b>{task.maintenanceNotes}</b>
                                                        <br />
                                                    </span>
                                                )}
                                                {task.taskWaiting === 1 && task.taskWaitingDesc && (
                                                    <span>
                                                        <br />
                                                        <FontAwesomeIcon style={{ color: "orange" }} icon="pause" /> {task.taskWaitingDesc}
                                                    </span>
                                                )}
                                                {task.taskNote && (
                                                    <span>
                                                        <br />
                                                        <FontAwesomeIcon style={{ color: "orange" }} icon="sticky-note" /> {task.taskNote}
                                                    </span>
                                                )}
                                            </Typography>
                                            <div style={{ paddingTop: 10 }}>
                                                {task.taskFiles
                                                    ? task.taskFiles.map((image, index) => (
                                                          <img
                                                              style={{
                                                                  paddingLeft: 2,
                                                                  paddingRight: 2
                                                              }}
                                                              key={index}
                                                              height="50"
                                                              width="50"
                                                              className="uploaded-image"
                                                              src={!isVideo(image) ? image : require("../assets/video.png")}
                                                              alt={image.split("/").pop()}
                                                              onClick={() => this.openLightbox(task.taskFiles, index)}
                                                          />
                                                      ))
                                                    : null}
                                            </div>
                                            <FormControl className={classes.formControl}>
                                                <FormLabel component="legend">{t("list.Status")}</FormLabel>

                                                <RadioGroup
                                                    name="status"
                                                    readOnly={true}
                                                    aria-label={t("list.Status")}
                                                    value={task.taskStatus}
                                                    onChange={e => {
                                                        this.setTaskStatus(e, task.taskId);
                                                    }}
                                                >
                                                    <FormControlLabel value={"0"} control={<Radio />} label={t("list.Pending")} />
                                                    <FormControlLabel value={"1"} control={<Radio />} label={t("list.Started")} />
                                                    <FormControlLabel value={"2"} control={<Radio />} label={t("list.Completed")} />
                                                    {this.state.accessLevel > 1 ? <FormControlLabel value={"3"} control={<Radio />} label={t("list.Waiting")} /> : null}
                                                </RadioGroup>
                                                <FormHelperText id="status-warning">{t("list.StatusChangeWarning")}</FormHelperText>
                                            </FormControl>

                                            <div className={classes.grow}>
                                                {task.propertyHasSmartLock > 0 && (
                                                    <div className={classes.lock}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.button}
                                                            style={
                                                                this.state.actionId === task.propertyId && this.state.actionType === 2 && !this.state.front_loading
                                                                    ? {
                                                                          backgroundColor: "red"
                                                                      }
                                                                    : null
                                                            }
                                                            disabled={!this.state.openEnabled || task.propertyHasSmartLock === 1}
                                                            onClick={() => {
                                                                this.handleOpenDoor(task.propertyId, 2);
                                                            }}
                                                        >
                                                            {this.state.front_loading ? (
                                                                <CircularProgress size={24} />
                                                            ) : this.state.actionId === task.propertyId && this.state.actionType !== 1 ? (
                                                                t("list.PressAgain")
                                                            ) : (
                                                                t("list.FrontDoor")
                                                            )}
                                                        </Button>
                                                        <br />
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.button}
                                                            style={
                                                                this.state.actionId === task.propertyId && this.state.actionType === 1 && !this.state.front_loading
                                                                    ? {
                                                                          backgroundColor: "red"
                                                                      }
                                                                    : null
                                                            }
                                                            disabled={!this.state.openEnabled || task.propertyHasSmartLock === 3}
                                                            onClick={() => {
                                                                this.handleOpenDoor(task.propertyId, 1);
                                                            }}
                                                        >
                                                            {this.state.apt_loading ? (
                                                                <CircularProgress size={24} />
                                                            ) : this.state.actionId === task.propertyId && this.state.actionType !== 2 ? (
                                                                t("list.PressAgain")
                                                            ) : (
                                                                t("list.AptDoor")
                                                            )}
                                                        </Button>
                                                    </div>
                                                )}

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={() => {
                                                        localStorage.setItem("maBackTo", "/pending/" + this.props.match.params.id);
                                                        this.props.history.push("/edit/" + task.taskId + "");
                                                    }}
                                                >
                                                    {t("list.Edit")}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    style={
                                                        this.state.actionId === task.taskId
                                                            ? {
                                                                  backgroundColor: "red",
                                                                  marginLeft: 15
                                                              }
                                                            : { marginLeft: 15 }
                                                    }
                                                    onClick={() => {
                                                        this.handleDelete(task.taskId);
                                                    }}
                                                >
                                                    {t("list.Delete")}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    style={{ marginLeft: 15 }}
                                                    onClick={() => {
                                                        this.handleReject(task.taskId, task.taskRejected);
                                                    }}
                                                >
                                                    {t("list.Reject")}
                                                </Button>
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <Divider className={classes.divider} />
                                </div>
                            );
                        })}
                </List>

                <Fab
                    color="primary"
                    aria-label={t("list.Add")}
                    className={classes.fab}
                    onClick={() => {
                        localStorage.setItem("maBackTo", "/pending/" + this.props.match.params.id);
                        this.props.history.push("/new/");
                    }}
                >
                    <AddIcon />
                </Fab>
            </div>
        );
    }
}

PendingTasksList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withSnackbar(withStyles(styles)(PendingTasksList))));
