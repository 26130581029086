import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "./App.css";
import * as serviceWorker from "./serviceWorker";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Login from "./components/Login";
import Settings from "./components/Settings";
import TasksList from "./components/TasksList";
import Add from "./components/Add";
import Calendar from "./components/Calendar";
import AddMultiple from "./components/AddMultiple";
import Edit from "./components/Edit";
import PendingTasksList from "./components/PendingTasksList";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

const theme = createTheme({
    palette: {
        primary: { main: "#f57c00", contrastText: "#ffffff" },
        secondary: { main: "#dc6f00", contrastText: "#000000" }
    }
});

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/" component={TasksList} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/calendar" component={Calendar} />
                        <Route exact path="/new-multiple" component={AddMultiple} />
                        <Route exact path="/new" component={Add} />
                        <Route exact path="/edit/:id" component={Edit} />
                        <Route exact path="/settings" component={Settings} />
                        <Route exact path="/pending/:id" component={PendingTasksList} />
                        <Redirect to="/" />
                    </Switch>
                </HashRouter>
            </MuiThemeProvider>
        );
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
