import axios from "axios";

const fetchClientExternal = () => {
    const defaultOptions = {
        method: "get",
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    return instance;
};

export default fetchClientExternal();
